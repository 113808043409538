<template>
  <el-dialog
    ref="dateDialog"
    :before-close="onDialogClose"
    :close-on-click-modal="false"
    :destroyOnClose="true"
    :showClose="false"
    :title="title"
    :visible.sync="visible"
    width="70%"
  >
    <template slot="title">
      <el-row>
        <el-col :span="20">
          <h1>Manage Calendar</h1>
        </el-col>
        <el-col :span="4" class="child-right-align">
<!--          <el-button round size="small" type="primary" @click="onsave">Save</el-button>-->
          <el-button round size="small" type="outlined" @click="closeDialog">Close</el-button>
        </el-col>
      </el-row>
    </template>
    <template>
      <div style="padding-top:5px;">
        <el-row style="margin-bottom: 10px;">
          <el-col :span="24">
            <el-date-picker
              v-model="year"
              class="year-picker"
              format="yyyy"
              type="year"
              value-format="yyyy"
              :clearable="false"
            ></el-date-picker>
          </el-col>
        </el-row>
        <div class="default-table-style">
          <el-table
            height="513"
            :data="calendarModifyList.filter(x => !x.isDeleted)"
            header-row-class-name="table-header-blue"
          >
            <el-table-column
              label="Date*"
              prop="calendarDate"
            >
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.calendarDate"
                  format="dd/MM/yyyy"
                  :default-value="year"
                  @change="() => onInputChange(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="Non-Working / Half-Day*"
              prop="fullDay"
              width="320"
            >
              <template slot-scope="scope">
                <el-radio-group v-model="scope.row.fullDay" @change="() => onInputChange(scope.row)">
                  <el-radio :label="true">Non-Working Day</el-radio>
                  <el-radio :label="false">Half Day</el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
            <el-table-column
              label="Description"
              prop="description"
            >
              <template slot-scope="scope">
                <el-input v-model="scope.row.description" @change="() => onInputChange(scope.row)"/>
              </template>
            </el-table-column>
            <el-table-column label="Operate" align="center">
              <template slot-scope="scope">
                <el-button
                  size="small"
                  type="primary"
                  round
                  @click="saveRove(scope.row, scope.$index)">save</el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="Add/Remove"
              prop=""
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="calendarModifyList.filter(x => !x.isDeleted).length === (scope.$index)+1"
                  circle
                  icon="el-icon-plus" size="mini"
                  type="success"
                  @click="addRow(scope.row)"></el-button>
                <el-button
                  v-else
                  circle
                  icon="el-icon-close"
                  size="mini"
                  type="danger"
                  @click="removeRow(scope.row, scope.$index)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import moment from 'moment'

export default {
  name: 'addDate',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Title'// Manage Price Methodology
    },
    defaultYear: {
      type: String
    },
    calendarList: {
      type: Array,
      default: () => {
        return []
      }
    },
  },

  data () {
    // const currentYear = moment().format('YYYY')
    return {
      year: this.defaultYear,
      calendarModifyList: []
    }
  },
  watch: {
    visible (newVal) {
      if (newVal) {
        this.year = this.defaultYear
        this.calendarModifyList = this.$_.cloneDeep(this.calendarList)
        this.intializeCalendar()
      }
    },
    year (newState, oldState) {
      this.getYearCalendar()

    }
  },

  methods: {

    onInputChange (row) {
      row.isNew = false
    },

    async onsave () {

      // Create
      const createUrl = `${this.$apis.calendar}`
      const createList = this.calendarModifyList.filter(calendar => !calendar.isDeleted && !calendar.id && calendar.calendarDate && calendar.description)
      await createList.map(calendar => {
        this.$request.post({
          url: createUrl,
          data: calendar
        })
        return calendar
      })

      // Update item
      const updateList = this.calendarModifyList.filter(calendar => !calendar.isDeleted && calendar.id != null)
      await updateList.map(calendar => {
        const updateUrl = `${this.$apis.calendar}`
        this.$request.put({
          url: updateUrl,
          data: calendar
        })
        return calendar
      })

      // Delete item
      const deleteList = this.calendarModifyList.filter(calendar => calendar.isDeleted && calendar.id != null)
      await deleteList.map(calendar => {

        const deleteUrl = `${this.$apis.calendar}/${calendar.id}`
        this.$request.delete(deleteUrl)
        return calendar
      })
      setTimeout(this.closeDialog, 500)

    },

    intializeCalendar () {

      const newDate = {
        calendarDate: '',
        fullDay: true, // Default True
        description: '',
      }

      this.calendarModifyList.push(newDate)

    },

    closeDialog () {
      this.$refs.dateDialog.handleClose()
    },

    addRow () {
      const newDate = {
        calendarDate: '',
        fullDay: true, // Default True
        description: '',
      }
      this.calendarModifyList.push(newDate)
    },

    removeRow (row, index) {
      // row.isDeleted = true
      // this.calendarModifyList.splice(this.calendarModifyList.length)
      const deleteUrl = `${this.$apis.calendar}/${row.id}`
      this.$request.delete(deleteUrl).then(res => {
        if (res.code === 1000) {
          this.calendarModifyList.splice(index, 1)
        }
      })
    },

    saveRove(row, index) {

      const calendar = { ...row, calendarDate: new Date(row.calendarDate)}
      if (row.id) {
        // Update item
        const updateUrl = `${this.$apis.calendar}`
        this.$request.put({
            url: updateUrl,
            data: calendar
        }).then(res => {
          if(res.code === 1000) {
            this.calendarModifyList[index] = res.data
          }
        })
      } else {
        // Create
        const createUrl = `${this.$apis.calendar}`
        this.$request.post({
          url: createUrl,
          data: calendar
        }).then(res => {
          if(res.code === 1000) {
            this.$set(this.calendarModifyList, index, res.data)
          }
        })
      }
    },

    onDialogClose (done) {
      // done()+
      this.$emit('close')
      this.$emit('update:visible', false)
    },

    async getYearCalendar () {
      const url = `${this.$apis.getCalendarByYear}/${this.year}`
      await this.$request.get({
        url
      }).then(res => {
        if (res.code === 1000) {
          if (res.data) {
            this.calendarModifyList = res.data
          } else {
            this.calendarModifyList = []
          }
          this.intializeCalendar()
        }
      })
    }
  },
}
</script>

<style scoped>
.child-right-align {
  text-align: right;
}

/*.el-dialog__wrapper .el-dialog .el-dialog__body{*/
/*  padding-top: 10px!important;*/
/*}*/

.container {
  height: 100%;
  position: relative;
}

/*.el-dialog ::v-deep .el-dialog__body {*/
/*  padding-top: 10px!important;*/
/*}*/

.default-table-style ::v-deep tr.table-header-blue > th {
  background-color: #85C2F3!important;
  color: white!important;
  height: 48px!important;
}
</style>
